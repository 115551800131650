import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { roundNumber } from '../../../../utils/mathHelpers';

export default function LinearFeatures() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, 9, -2, -3, -4, -5, -6, -7, -8, -9];
    const nums2 = [3, 4, 5, 6];
    const practice_problems_num = 6;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random() * nums.length)];
      let secondNumber = nums[Math.floor(Math.random() * nums.length)];
      let thirdNumber = nums[Math.floor(Math.random() * nums.length)];
      let fourthNumber = nums2[Math.floor(Math.random() * nums2.length)];
      let integralUpper = nums[Math.floor(Math.random() * nums.length)];
      let integralLower = nums[Math.floor(Math.random() * nums.length)];

      while (integralLower >= integralUpper) {
        integralUpper = nums[Math.floor(Math.random()*nums.length)];
        integralLower = nums[Math.floor(Math.random()*nums.length)];
      }

      let sign1 = "+";
      let sign2 = "+";
      let sign3 = "+";

      if (firstNumber < 0) {
        firstNumber = Math.abs(firstNumber);
        sign1 = "-";
      }

      if (secondNumber < 0) {
        secondNumber = Math.abs(secondNumber);
        sign2 = "-";
      }

      if (thirdNumber < 0) {
        thirdNumber = Math.abs(thirdNumber);
        sign3 = "-";
      }

      let dx = (integralUpper - integralLower) / fourthNumber;
      let x = [];
      for (let i = integralLower; i <= integralUpper; i += dx) {
        x.push([i]);
      }
      let s = x.length;

      // Fill the array with calculations
      for (let i = 0; i < s; i++) {
        x[i][1] = Math.pow(x[i][0], 3) - firstNumber * Math.pow(x[i][0], 2) - secondNumber * x[i][0] + thirdNumber;
        x[i][2] = x[i][1];
        x[i][3] = x[i][2];
        x[i][4] = x[i][2];
        x[i][5] = x[i][2];
      }

      // Modify specific elements in the array
      for (let i = 1; i < s - 1; i++) {
        x[i][5] = 2 * x[i][5];
      }

      x[0][4] = 0;
      x[s - 1][3] = 0;

      // Calculate sums for the results
      let left = dx * x.reduce((sum, row) => sum + row[3], 0);
      let right = dx * x.reduce((sum, row) => sum + row[4], 0);
      let trapezoid = (dx / 2) * x.reduce((sum, row) => sum + row[5], 0);

      // Midpoint Riemann Sum calculation
      let midpointSum = 0;
      for (let i = 0; i < s - 1; i++) {
        let midpoint = (x[i][0] + x[i + 1][0]) / 2; // Calculate midpoint of each subinterval
        let midpointValue = Math.pow(midpoint, 3) - firstNumber * Math.pow(midpoint, 2) - secondNumber * midpoint + thirdNumber; // Polynomial evaluation at midpoint
        midpointSum += midpointValue;
      }
      midpointSum *= dx; // Multiply sum by dx to get the total midpoint sum

      generatedProblem.push({
        key: `${step}-rs`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        integralUpper: integralUpper,
        integralLower: integralLower,
        sign1: sign1,
        sign2: sign2,
        sign3: sign3,
        left: left,
        right: right,
        trapezoid: trapezoid,
        midpointSum: midpointSum
      });
    }

    let formattedProblemList = [];
    generatedProblem.forEach((problem) => {
      formattedProblemList.push(equationFormatter(problem));
    });

    setFormattedProblems(formattedProblemList);
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: (
        <span>
            <span>{`$ \\int_{${equation.integralLower}}^{${equation.integralUpper}} x^{3} ${equation.sign1} ${equation.firstNumber}x^{2} ${equation.sign2} ${equation.secondNumber}x ${equation.sign3} ${equation.thirdNumber} \\, dx \\, \\, \\, \\, \\, \\, n = ${equation.fourthNumber} $`}</span>
        </span>
      ),
      
      answer: (
        <span>
          <span>{`$ \\text{Left} = ${roundNumber(equation.left)} $`}</span>
          <br />
          <span>{`$ \\text{Right} = ${roundNumber(equation.right)} $`}</span>
          <br />
          <span>{`$ \\text{Trapezoid} = ${roundNumber(equation.trapezoid)} $`}</span>
          <br />
          <span>{`$ \\text{Midpoint} = ${roundNumber(equation.midpointSum)} $`}</span>
          <br />
        </span>
      ),
    };
  }

  function helpText() {
    return `
      $$ -4x + 3y = 5 $$    
      \
      $$ m = \\frac{4}{3} $$
      `;
  }

  return (
    <div>
      <EquationLayout
        title={"Calculus Reimann Sums"}
        instructions={"Compute the Left, Right, Midpoint, and Trapezoid Reimann Sums to compute approximate the Integral."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{ title: "Riemann Sums Help", helpTextFunc: helpText }}
      />
    </div>
  );
}
